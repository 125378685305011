// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-projects-index-js": () => import("./../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-client-panel-page-js": () => import("./../../src/templates/client-panel-page.js" /* webpackChunkName: "component---src-templates-client-panel-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-lab-page-js": () => import("./../../src/templates/lab-page.js" /* webpackChunkName: "component---src-templates-lab-page-js" */),
  "component---src-templates-offer-page-js": () => import("./../../src/templates/offer-page.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-project-view-js": () => import("./../../src/templates/project-view.js" /* webpackChunkName: "component---src-templates-project-view-js" */)
}

